import React from 'react'

import { TreemappingsectionStyles } from './Treemappingsection.styles'
import Image from 'components/ImgOptimized'

function Treemappingsection(props) {
  const {
    treemappingData: { TreemappingProps },
    showFullimages,
  } = props
  const {
    data: {
      treemappingthumb: {
        childImageSharp: { fluid: treemappingthumb },
      },
    },
  } = props
  return (
    <TreemappingsectionStyles className="treemappingSection">
      <div className="container">
        <div className="businessHeading">
          <h2>{TreemappingProps.title}</h2>
        </div>
        <div className="engagementContent">
          <div className="TreemappingContent">
            <div className="left-part"></div>
            <div className="right-part">
              <p>{TreemappingProps.maincontent}</p>
              {showFullimages && (
                <figure>
                  <Image
                    fluid={treemappingthumb}
                    lazyload={{
                      enabled: true,
                      once: true,
                      offset: 1000,
                      heigth: 410,
                    }}
                  />
                </figure>
              )}
            </div>
          </div>
          <div className="bottomTreemappingContent">
            <div className="left-part">
              {TreemappingProps.bottomTitle ? (
                <div className="bottom-content">
                  <h4>{TreemappingProps.bottomTitle}</h4>
                  <p>{TreemappingProps.bottomContent}</p>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="right-part">
              <p>{TreemappingProps.bottompara}</p>
            </div>
          </div>
        </div>
      </div>
    </TreemappingsectionStyles>
  )
}

export default Treemappingsection
