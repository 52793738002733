import React, { memo } from 'react'
import styled from 'styled-components'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { graphql } from 'gatsby'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { SentMapProps } from 'components/Props/sentmap'
import Casebanner from 'components/Casebanner/Casebanner'
import Caseintroduction from 'components/Caseintroduction/Caseintroduction'
import Processinginformationsec from 'components/Processinginformationsec/Processinginformationsec'
import Resultimgsection from 'components/Resultimgsection/Resultimgsection'
import Dealingbigdatasec from 'components/Dealingbigdatasec/Dealingbigdatasec'
import { xl, lg, md } from 'config/variables'
import Treemappingsection from 'components/Treemappingsection/Treemappingsection'
import Timeanalyticssec from 'components/Timeanalyticssec/Timeanalyticssec'
import Relatedcaselist from 'components/Relatedcaselist/Relatedcaselist'
import Casecontactcardinfo from 'components/Casecontactcardinfo/Casecontactcardinfo'

export const SentMapStyle = styled.div`
  .casebanner {
    .container {
      .img-block {
        .banner-img {
          .banner-bg {
            background-color: #d8d3cf;
          }
        }
      }
    }
  }
  .Processinginformationsec {
    .casequote-section {
      margin-bottom:0px;
    }
  }
  .Resultimgsection {
    margin-bottom:120px;
    ${xl(`
      margin-bottom:90px;
    `)}
    ${lg(`
       margin-bottom:60px;
    `)}
    ${md(`
        margin-bottom:40px;
    `)}
    .container {
      .content-blk{
        flex: 0 1 602px;
        ${xl(`
          flex: 1 1 auto;
        `)}
        ${lg(`
          flex: 1 1 auto;
        `)}
      }
      
    }
    .img-blk {
      bottom:0;
      top:0px;
      display:flex;
      .imageWrap {
        flex: 0 0 1230px;
        margin:auto;
        ${xl(`
          flex: 1 1 auto;
        `)}
        ${lg(`
          flex: 1 1 auto;
        `)}
      }
    }
  }
`

const SentMap = memo(props => {
  const { data } = props
  const { location } = props
  const managingDeashboard = props.data.managingDeashboard.childImageSharp.fluid

  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  return (
    <Layout mainClass="case-studie-pages" location={location}>
      <SEO
        title="SentMap"
        description="SentMap & Simform built real-time sentiment-based market analytics engine for portfolio managers to determine trends in sentiments based on stock price movements."
        keywords={[`simform`]}
        meta={[
          {
            name: 'ROBOTS',
            content: 'NOINDEX, NOFOLLOW',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <SentMapStyle>
        <Casebanner
          bannerdata={SentMapProps}
          {...props}
          showFullimages={true}
        />
        <Caseintroduction
          caseintrodata={SentMapProps}
          {...props}
          showFullimages={false}
        />
        <div className="lazy-load-div">
          <Processinginformationsec
            Processinginformationdata={SentMapProps}
            {...props}
          />
          <Resultimgsection resultimgsectiondata={SentMapProps} {...props} />
        </div>
        {showContent && (
          <>
            <Dealingbigdatasec
              dealingbigdata={SentMapProps}
              {...props}
              showDeashboardImage
            />
            <Treemappingsection
              treemappingData={SentMapProps}
              {...props}
              showFullimages
            />
            <Timeanalyticssec
              timeanalyticsData={SentMapProps}
              timeAnalyticsImg={managingDeashboard}
              {...props}
            />
            <Relatedcaselist relatedcaselistdata={SentMapProps} {...props} />
            <Casecontactcardinfo Casecontactcardinfodata={SentMapProps} />
          </>
        )}
      </SentMapStyle>
    </Layout>
  )
})
export default SentMap
export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/sent-map-banner@2x.jpg/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 570) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    processingInfo: file(
      relativePath: { regex: "/processing-information@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1166) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    redbulleventscreen: file(
      relativePath: { regex: "/sentmap-mockup@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    sentmapdeashboard: file(
      relativePath: { regex: "/sentmap-deashboard@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dealingdiagram: file(relativePath: { regex: "/dealing-diagram@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    treemappingthumb: file(
      relativePath: { regex: "/treemapping-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 867) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    singlecase1: file(
      relativePath: { regex: "/ihealth-casestudie-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }

    singlecase2: file(
      relativePath: { regex: "/tryg-casestudies-thumb@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 220) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    managingDeashboard: file(
      relativePath: { regex: "/managing-deashboard@2x.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    businessFullImage: file(
      relativePath: { regex: "/way-point-intro-img@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1140) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
