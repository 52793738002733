import React from 'react'
import { images } from 'config/images'

export const SentMapProps = {
  bannerProps: {
    heading: 'SenTMap',
    bannerPara:
      'Sentiment based market analytics engine for portfolio managers',
    BannerAlt: 'SentMap Case Study',
  },
  introducrtionContent: {
    maintile: 'Introduction',
    mainpara: (
      <>
        Over time markets have become sentiment driven. Stock prices are heavily
        influenced by economic events, company/industry performance, and market
        sentiments. Major financial institutes are heavily investing in
        Sentiment based analysis and decision making for portfolio managers.
        <br /> <br />
        SenTMap is an advanced research tool designed to keep investors up to
        date with analyzed financial news – so they can focus on what matters to
        their holdings and make better decisions. It gives investors confidence
        that valuable information is being extracted from the fire hose of
        financial news.
      </>
    ),
  },
  ProcessinginformationProps: {
    subTitle: (
      <>Processing Firehose of information to get the right information</>
    ),
    para: (
      <>
        The biggest challenge was to develop a backend system that can churn all
        the data coming from thousands of News Broadcast channels. After
        operating on that data, the system must present meaningful insight to
        the investors which will help them to understand the bearish or bullish
        trend of the market in real-time. The system should be scalable on both
        backend and frontend so that real-time states, historical states, and
        often merging of states can be handled smoothly. <br /> <br />
        The other major challenge was to present that continuously changing data
        into different reports, charts, and Three-dimensional trends in
        real-time. Unlike prepackaged visualizations, SenTMap required custom
        built charts display sentiments in way that required minimum visual
        processing.
      </>
    ),
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “We selected Simform for our financial technology visualization
            system that has very complex processes and 3D visualization.
          </span>{' '}
          Of all the teams we worked with, Simform had the best service, was
          available for discussions, and made time to answer any questions we
          had.”
        </>
      ),
      userImg: images.danJoldzic,
      userImg2x: images.danJoldzic2x,
      userName: 'Dan Joldzic',
      designation: 'CEO',
    },
  },
  ResultimgsectionProps: {
    heading: 'Result',
    content: (
      <>
        Market sentiment which can be positive(bullish) or negative(bearish) is
        output of people’s emotions, opinions and speculation towards any
        particular stock or entire market.
        <br />
        <br />
        It is as important as fundamental and technical analysis. However, too
        much information can leave portfolio managers swamped and raises the
        problem of information overload. Unlike standard knowledge management
        system, SenTMap helps them keep the whole picture of financial news in
        view, including its market impact.
        <br />
        <br />
        We built a scalable real-time analytics engine that can perform analysis
        based on real-time News Data to determine the trends in sentiments and
        stock price movements. Portfolio managers can now stay aligned with the
        large volume of processed content, and they can focus on what is really
        important for the investment.
      </>
    ),
  },
  dealingbigdataProps: {
    heading: 'Dealing with Big Data in real time ',
    para: (
      <>
        When a system is ingested with news articles from thousands of sources
        constantly, you need single threaded server-side tech to process and
        execute all the requests without fail, and scalable server instances
        with high processing power. As we were not storing data anywhere, it was
        more difficult to process them in real-time. A leak of few articles or
        news can lead to wrong market sentiment.
      </>
    ),

    bottomPara: (
      <>
        We used Azure Event hubs to continuously ingress the data from different
        news sources with low latency and configurable time retention. Along
        with that, combination of ExpressJS, NodeJS and BackboneJS helped us to
        efficiently process the data on server-side. Processed data is then
        pushed to the client side, which ultimately boosts the performance.
      </>
    ),
    mainImgcaption:
      'Sentmap backend architecture with services used for news data analysis',
    caseQuoteProps: {
      maintext: (
        <>
          <span>
            “As we moved to project stage, Simform proved they would go the
            extra mile to make sure that the project requirements were met.
          </span>{' '}
          The team worked really hard and was able to quickly learn new
          libraries specific to Finance and implement them effectively. As far
          as the application they built, it is running at a very high level. We
          expect to be working with them again for our future development
          needs.”
        </>
      ),
      userImg: images.danJoldzic,
      userImg2x: images.danJoldzic2x,
      userName: 'Dan Joldzic',
      designation: 'CEO',
    },
  },
  TreemappingProps: {
    title: 'Presenting the processed data using 3D charts and Treemapping',
    bottomTitle: '3D CHARTING',
    bottomContent: (
      <>
        Using three.js <br />
        library
      </>
    ),
    maincontent:
      'Portfolio managers love to get quick reports as it helps them buying, holding, or selling decisions. It was important to convert raw news data into technical charts which can notify investors whether the market sentiment for any specific stock or company is bullish or bearish. Unlike prepackaged visualizations, SenTMap required custom-built charts to display sentiments in a way that required minimum visual processing.',

    bottompara:
      'Along with popular charts in the financial market like line, bar, and candlestick, SenTMap required 3D charting capabilities with heat map. To build highly customized 3D charts, we utilized three.js, a Javascript 3D library to create 3D graphics. More complicated implementations involved integrating geometries, lighting and data mesh. To build Treemaps, we utilized squarified treemapping algorithm which is widely accepted by a broad audience, especially in financial contexts. ',
  },
  TimeanalyticsProps: {
    Title: 'Managing real time analytics',
    Para:
      'Stock market is highly volatile and it is difficult to predict the future. One bad/negative news about the company and it can create panic in the stock market. It is very important to keep investors updated with realtime news data.',
    TimeanalyticsList: [
      {
        IconUrl: images.scalabilityIcon,
        ListTitle: 'Scalability of real-time analytics',
        ListDetails:
          'We utilized the non-blocking nature of node.js to channel the flow of real time information to our application. Utilizing node.js, we were also able to reduce the server RAM requirements to minimum to reach 1M concurrent users. ',
      },
      {
        IconUrl: images.optimizationIcon,
        ListTitle: 'Rigorous testing & optimization',
        ListDetails:
          'Given the sheer number of cases where product could break, we started testing from very early stages of the project. Code quality protocols were followed to performance and bug detection.',
      },
      {
        IconUrl: images.asynchronousIcon,
        ListTitle: 'Asynchronous data syncing',
        ListDetails:
          'We initially planned synchronous data handling, but that resulted in a poor user experience as the pages remained blocked. We then took the difficult route of asynchronously combining all software components that resulted in a better user experience.',
      },
    ],
  },
  relatedcaselistProps: {
    heading: 'Related Case Studies',
    caselistProps: [
      {
        title: 'iHealth',
        para:
          'Clinically validated health monitoring and fitness coaching solution',
        className: 'soft-pink-two',
        link: '/ihealth-monitoring-platform/',
      },
      {
        title: 'Tryg',
        para:
          'Scandinavia`s second largest general insurer brings transparency to insurance claims with telematics',
        className: 'light-peach-five',
        link: 'case-studies/tryg/',
      },
    ],
  },
  CasecontactcardinfoProps: {
    CTACard: {
      title: (
        <>
          Speak to our experts to unlock the value Mobility, IoT, and Data
          Insights!
        </>
      ),
      buttonName: 'Contact Us',
      buttonLink: '/contact/',
    },
  },
}
