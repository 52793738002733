import styled from 'styled-components'
import { bodyFont, md, xl, lg } from 'config/variables'

export const TreemappingsectionStyles = styled.div`
position: relative;
  margin-bottom: 120px;
  ${xl(`
      margin-bottom: 100px;
  `)}
  ${lg(`
      margin-bottom: 60px;
  `)}
  ${md(`
      margin-bottom: 40px;
  `)}
  .engagementContent {
    display: flex;
    justify-content: space-between;
    flex-direction:column;
    .TreemappingContent {
      display:flex;
    }
    .left-part {
      flex:0 0 273px;
      padding-right:82px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      ${xl(`
          flex:0 0 273px;
          padding-right: 82px;
      `)}
      ${lg(`
          flex:0 0 185px;
          padding-right: 15px;
      `)}
      ${md(`
          display:none;
      `)}
      .bottom-content {
        position: relative;
        padding-top: 21px;
        &:before {
          content: '';
          position: absolute;
          width: 55px;
          height: 3px;
          background: #3d3d3d;
          top: 0;
          left: 0;
        }
        h4 {
          font-size: 15px;
          font-weight: 600;
          font-family: ${bodyFont};
          margin-bottom: 6px;
          text-transform: uppercase;
          line-height: 21px;
        }
        p {
          font-size: 15px;
          letter-spacing: -0.16px;
          line-height: 21px;
          margin: 0;
        }
      }
    }
    .right-part {
      flex: 1 1 auto;
      width: 100%;
      margin: 0 0 0 auto;
      ${xl(`
          flex:1 1 auto;
      `)}
        ${md(`
            flex:1 1 auto;
        `)}
      ${md(`
            max-width: 100%;
        `)}
      p {
        margin: 0px;
      }
      figure {
        ${xl(`
            margin:50px 0 27px;
        `)}
        ${lg(`
            margin:50px 0 27px;
        `)}

        ${lg(`
            margin:20px 0;
        `)}
        
      }
    }
    .bottomTreemappingContent {
      display: flex;
      justify-content:space-between;
    }
  }
`
